<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :isManySelect="true"
      :tableUrl="tableUrl"
      :selectable="selectable"
      :searchParam.sync="searchParam"
      @selection-change="selectionChange"
    >
      <template #headBtnSlot>
        <v-button text="返回" @click="goback"></v-button>
      </template>
      <template #operateSlot="scope">
        <v-button
          text="发放"
          v-if="!scope.row.hasGrantPoint"
          type="text"
          @click="send([scope.row])"
        />
        <v-button
          class="sended"
          type="text"
          :text="`已发放${scope.row.pointCount}积分`"
          :disabled="true"
          v-else
        >
        </v-button>
      </template>
      <template #batchSlot="scope">
        <v-button
          text="批量发放"
          :disabled="scope.selectedData.data.length === 0"
          @click="batchSend(scope.selectedData)"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getCommentPraiseRankingListURL, doTopicURL } from "./api.js";
import {
  statusMap,
  setStatusOps,
  isTopMap,
  setIsTopOps,
  sortFieldMap,
  sortFieldOps,
} from "./map.js";
import { createDivAndPVNode, createDivAndImgVNode } from "@/utils/utils.js";

export default {
  name: "topicSortList",
  data() {
    return {
      searchParam: {
        topicId: "",
      },
      sortFieldOps: sortFieldOps(),
      sortFieldMap,
      isTopOps: setIsTopOps(),
      statusOps: setStatusOps(),
      tableUrl: getCommentPraiseRankingListURL,
      headers: [
        {
          prop: "userName",
          label: "用户姓名",
        },
        {
          prop: "nickName",
          label: "用户昵称",
        },
        {
          prop: "commentContent",
          label: "用户发表评论内容",
          showOverflowTooltip: false,
          minWidth: 220,
          formatter: (row, prop) => {
            return createDivAndPVNode(this, row, prop);
          },
        },
        {
          prop: "commentPic",
          minWidth: 320,
          label: "用户发表评论图片",
          formatter: (row, prop) => {
            return createDivAndImgVNode(this, row, prop, {
              childOpts: {
                style: {
                  marginBottom: "10px",
                },
                childOpts: {
                  style: {
                    marginRight: "10px",
                  },
                },
              },
            });
          },
        },
        {
          prop: "praiseCount",
          label: "点赞总数",
        },
      ],
    };
  },
  computed: {
    ...mapState("app", ["sidebar", "userInfo"]),
    createTime: {
      get() {
        return !this.searchParam.createTimeS && !this.searchParam.createTimeE
          ? []
          : [this.searchParam.createTimeS, this.searchParam.createTimeE];
      },
      set(val) {
        [this.searchParam.createTimeS, this.searchParam.createTimeE] = val || [
          "",
          "",
        ];
      },
    },
  },
  created() {
    this.searchParam.topicId = this.$route.query.id;
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    selectable(row) {
      if (row.hasGrantPoint) {
        return false;
      }
      return true;
    },
    batchSend(list) {
      console.log("list--");
      const { data, ids } = list;
      const needSendList = data.filter((v) => !v.hasGrantPoint);
      this.send(needSendList);
    },
    send(list) {
      this.$confirm("是否确定发送?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const data = list.map((v) => ({
          userId: v.userId,
          communityId: v.communityId || undefined,
          roomId: v.roomId || undefined,
        }));
        this.$axios
          .post(`${doTopicURL}`, {
            data,
            relationId: this.searchParam.topicId,
          })
          .then((res) => {
            if (res.code === 200) {
              this.$refs.list.search();
            }
          });
      });
    },
    selectionChange(val) {
      console.log(val, "--val--");
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
  .sended {
    color: #409eff;
  }
}
</style>
